

import { Component, Vue, Prop } from 'vue-property-decorator';
import { inject } from '@/inversify';
import EventsFilterService, { EventsFilterServiceS } from '@/modules/events/events-filter.service';
import CustomMultiSelect, { Item } from '@/modules/common/components/ui-kit/custom-multi-select.vue';
import StyledSearchbar from '@/modules/common/components/styled-searchbar.vue';

@Component({
    components: { CustomMultiSelect, StyledSearchbar },
})
export default class CountryFilter extends Vue {
    @inject(EventsFilterServiceS) private eventsFilterService!: EventsFilterService;

    @Prop({ type: Boolean, default: false })
    isDisabled!: boolean;

    private query: string | null = null;

    onVisibleChange(visible: boolean) {
        if (!visible) {
            return;
        }

        this.rawItems = this.eventsFilterService.allCountries
            .map(c => ({
                value: c,
                name: c.toUpperCase(),
                disabled: false,
            } as Item))
            .sort((a, b) => {
                const isASelected = this.countries.some(item => item.value === a.value) ? -1 : 1;
                const isBSelected = this.countries.some(item => item.value === b.value) ? -1 : 1;

                return isASelected - isBSelected;
            });
    }

    private rawItems: Item[] = [];

    get items() {
        let searchResult = this.rawItems;

        if (this.query) {
            const searchPattern = new RegExp(this.query.toLowerCase());
            searchResult = this.rawItems.filter(type => searchPattern.test(type.name.toLowerCase()));
        }

        if (!searchResult.length) {
            searchResult.push({
                name: this.$tc('noResults'),
                value: 'No results',
                disabled: true,
            });
        }

        return searchResult;
    }

    get countries(): Item[] {
        const { countries } = this.eventsFilterService.settings;

        return countries.map((value): Item => ({
            value,
            name: value,
        }));
    }

    set countries(items: Item[]) {
        this.eventsFilterService.settings = {
            ...this.eventsFilterService.settings,
            countries: items.map(item => item.value as string),
        };
    }

    handleChange(query: string) {
        this.query = query;
    }

    mounted() {
        this.onVisibleChange(true);
    }
}
